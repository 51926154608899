import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: 400,
    // overflow: auto,
  },
});

const rows = [
  {
    DETECTION_TYPE: "Disconnect Tamper",
    TIMESTAMP: "2020-02-01 08:44:22",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888212342",
    METER_NUM: "123234",
    SUSPECT_NAME: "Nancy Joseph",
    CONFIDENCE: "90",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Outage Tamper",
    TIMESTAMP: "2020-02-01 03:14:24",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888212322",
    METER_NUM: "321421",
    SUSPECT_NAME: "David Anderson",
    CONFIDENCE: "95",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Disconnect Tamper",
    TIMESTAMP: "2020-02-02 05:45:25",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888212897",
    METER_NUM: "854930",
    SUSPECT_NAME: "Kevin Clemons",
    CONFIDENCE: "80",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Disconnect Tamper",
    TIMESTAMP: "2020-02-03 18:14:12",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888216548",
    METER_NUM: "325124",
    SUSPECT_NAME: "Joshy Joseph",
    CONFIDENCE: "90",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Disconnect Tamper",
    TIMESTAMP: "2020-02-07 08:34:02",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888212319",
    METER_NUM: "948339",
    SUSPECT_NAME: "Angela White",
    CONFIDENCE: "93",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Load Side Voltage",
    TIMESTAMP: "2020-02-08 12:24:09",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888213342",
    METER_NUM: "784939",
    SUSPECT_NAME: "Pamela Beesly",
    CONFIDENCE: "95",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Outage Tamper",
    TIMESTAMP: "2020-03-01 18:54:32",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888212545",
    METER_NUM: "539231",
    SUSPECT_NAME: "David Hugh",
    CONFIDENCE: "90",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Load Side Voltage",
    TIMESTAMP: "2020-03-01 01:12:23",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888223412",
    METER_NUM: "321093",
    SUSPECT_NAME: "Lisa Ann",
    CONFIDENCE: "92",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Disconnect Tamper",
    TIMESTAMP: "2020-03-02 09:32:22",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888212339",
    METER_NUM: "738282",
    SUSPECT_NAME: "David Brent",
    CONFIDENCE: "96",
    CREW_ONSITE: "CREW",
  },
  {
    DETECTION_TYPE: "Disconnect Tamper",
    TIMESTAMP: "2020-03-04 08:44:22",
    SERVICE_ORDER: "12345-S1",
    ESIID: "100888219987",
    METER_NUM: "985632",
    SUSPECT_NAME: "Michael Keaton",
    CONFIDENCE: "94",
    CREW_ONSITE: "CREW",
  },
];

function SuspectListReport() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card>
      <CardContent>
        {/* <Typography color="textSecondary" gutterBottom>
          Documents
        </Typography> */}
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Detection Type</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Service Order</TableCell>
                <TableCell>SDP ID</TableCell>
                <TableCell>Meter Number</TableCell>
                <TableCell>Confidence</TableCell>
                <TableCell>Suspect Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.ESIID}>
                  <TableCell>{row.DETECTION_TYPE}</TableCell>
                  <TableCell>{row.TIMESTAMP}</TableCell>
                  <TableCell>{row.SERVICE_ORDER}</TableCell>
                  <TableCell>{row.ESIID}</TableCell>
                  <TableCell>{row.METER_NUM}</TableCell>
                  <TableCell>{row.CONFIDENCE}</TableCell>
                  <TableCell>{row.SUSPECT_NAME}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

export default SuspectListReport;
