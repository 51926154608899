import React from "react";
import Chart from "react-apexcharts";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

function MA4UChart(props) {
  return (
    <div>
      <Card>
        <CardContent>
          <Typography component={'div'} color="textPrimary" gutterBottom>
            {props.title}
          </Typography>
          <Chart
            options={props.options}
            series={props.series}
            type={props.type}
            height={props.height ? props.height : '300'}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default MA4UChart;
