import React from "react";
import DayJS from "react-dayjs";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { getDataFromApi } from "../../services/rest-api.service";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: 400,
    // overflow: auto,
  },
});

const rows = [
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "107954",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "109654",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "100376",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "105774",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "107063",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "108809",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "103927",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "102752",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "107868",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
  {
    AGING_DAYS: "3",
    BILLING_CYCLE: "12",
    COLLECTION_NODE: "X",
    COLLECTION_TIME: "2020-01-01",
    CUSTOMER_CLASS: "CC",
    DEVICE_CATEGORY: "ABC",
    FIRMWARE: "1.01.01.01.00",
    LAST_READ_DATE: "Wed, 20 Mar 2019 00:00:00 GMT",
    METER_ID: "108782",
    METER_TYPE: "123",
    REISTER_UOM: "1.01",
    SERVICE_ORDER_TYPE: "123456-S1",
    STATUS: "ACTIVE",
  },
];

function MissReadDetail() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    getDataFromApi("apiForMissReadDetail").then((data) => {
      console.log(data);
      setTableData(data);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Missed Read Details
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Meter ID</TableCell>
                <TableCell>Billing Cycle</TableCell>
                <TableCell>Last Read Date</TableCell>
                <TableCell>Cust. Class</TableCell>
                <TableCell>Meter Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Aging Days</TableCell>
                <TableCell>Service Order</TableCell>
                <TableCell>Collection Time</TableCell>
                <TableCell>Collection Node</TableCell>
                <TableCell>Device Category</TableCell>
                <TableCell>Firmware</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.METER_ID}>
                  <TableCell>{row.METER_ID}</TableCell>
                  <TableCell>{row.BILLING_CYCLE}</TableCell>
                  <TableCell>
                    <DayJS format="YYYY-MM-DD">{row.LAST_READ_DATE}</DayJS>
                  </TableCell>
                  <TableCell>{row.CUSTOMER_CLASS}</TableCell>
                  <TableCell>{row.STATUS}</TableCell>
                  <TableCell>{row.METER_TYPE}</TableCell>
                  <TableCell>{row.AGING_DAYS}</TableCell>
                  <TableCell>{row.SERVICE_ORDER_TYPE}</TableCell>
                  <TableCell>{row.COLLECTION_TIME}</TableCell>
                  <TableCell>{row.COLLECTION_NODE}</TableCell>
                  <TableCell>{row.DEVICE_CATEGORY}</TableCell>
                  <TableCell>{row.FIRMWARE}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

export default MissReadDetail;
