import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AMITrend from "./AMITrend";
import ServiceOrderAging from "./ServiceOrderAging";
import PrevMonthOrders from "./PrevMonthOrders";
import AMIKPIs from "./AMIKPIs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function AMIReport() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        style={{
          padding: "20px",
        }}
      >
        <Grid item xs sm={8} md={8} key="AMITrend">
          <AMITrend />
        </Grid>
        <Grid item xs sm={4} md={4} key="AMIKPIs">
          <AMIKPIs />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        style={{
          padding: "20px",
        }}
      >
        <Grid item xs sm={8} md={6} key="PrevMonthOrders">
          <PrevMonthOrders />
        </Grid>
        <Grid item xs sm={8} md={6} key="ServiceOrderAging">
          <ServiceOrderAging />
        </Grid>
      </Grid>
    </div>
  );
}

export default AMIReport;
