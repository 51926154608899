import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";
import { getDataFromApi } from "../../services/rest-api.service";

function AMITrend() {
  const [loading, setLoading] = useState(true);
  const [seriesData, setSeriesData] = useState([
    // {
    //   name: "Missed Read",
    //   data: [137, 0, 0, 26],
    // },
  ]);

  const [AMITrendData, setAMITrendData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    getDataFromApi("apiForMissReadAge").then((data) => {
      setSeriesData([
        {
          name: "Missed Read",
          data: Object.values(data[0]),
        },
      ]);
      setLoading(false);
    });
    setAMITrendData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "Mar-19",
            "Apr-19",
            "May-19",
            "Jun-19",
            "Jul-19",
            "Aug-19",
            "Sep-19",
            "Oct-19",
            "Nov-19",
            "Dec-19",
            "Jan-20",
            "Feb-20",
          ],
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
      },
      series: [
        {
          name: "Actuals",
          data: [1256, 1501, 1537, 13891, 22016, 25094, 36012, 48092, 70152],
        },
        {
          name: "Target",
          data: [1500, 1500, 1500, 25000, 25000, 25000, 50000, 50000, 75000, 75000, 102591, 102591],
        },
      ],
    });
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Implementation Trending: Actual vs. Target"
          type="line"
          options={AMITrendData.options}
          series={AMITrendData.series}
          height="390"
        />
      )}
    </div>
  );
}

export default AMITrend;
