import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";

function MA4UKPI(props) {
  return (
    <div>
      <Card>
        <CardHeader
          avatar={<Avatar className={props.state}>{props.icon}</Avatar>}
          title={props.title}
          subheader={props.value}
        ></CardHeader>
      </Card>
    </div>
  );
}

export default MA4UKPI;
