import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import ConnectionStatusDetail from "./ConnectionStatusDetail";
import { getDataFromApi } from "../../services/rest-api.service";

function MDReconMissingInstalledMeter(props) {
  const [loading, setLoading] = useState(true);
  const [connMDReconSummaryData, setConnMDReconSummaryData] = useState({
    options: {},
    series: [],
  });


  useEffect(() => {
    const division = props.division ? props.division : 10;
    getDataFromApi("apiForMissingInstalledMeter", {division: division}).then((data) => {
      setConnMDReconSummaryData({
        options: {
          colors: ['#4CAF50', '#FF9800'],
          chart: {
            id: "mdReconSummaryChart",
            stacked: true,
            height: 200,
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                if (config.dataPointIndex === 0) {
                  if (props)
                    props.setDetailReportName("MDM");
                } else if (config.dataPointIndex === 1) {
                  if (props)
                    props.setDetailReportName("HES");
                } else {
                  if (props)
                    props.setDetailReportName("CIS");
                }
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              if (val > 0)
                return val;
            },
          },
          noData: {
            text: "Loading...",
          },
          legend: {
            position: "top",
            horizontalAlign: "center",
          },
          xaxis: {
            categories: [
              "Missing Meters MDM (vs. CIS)",
              "Missing Meters HES (vs. CIS)",
              "Missing Meters CIS (vs. HES)",
            ],
          },
          theme: {
            mode: "light",
            palette: "palette2",
          },
        },
        series: JSON.parse(data.body),
      });
      setLoading(false);
    });
    setTimeout(() => {
      setLoading(false);
      //ApexCharts.exec("connStatusChart", "toggleSeries", "Missing Meters");
    }, 2000);
  }, [props.division]);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Missing Installed Meter"
          type="bar"
          options={connMDReconSummaryData.options}
          series={connMDReconSummaryData.series}
          height="200"
        />
      )}
      
    </div>
  );
}

export default MDReconMissingInstalledMeter;
