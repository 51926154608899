import React from "react";
import DayJS from "react-dayjs";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: 400,
    // overflow: auto,
  },
});

const rows = [
  {
    id: 1,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931198",
  },
  {
    id: 2,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "Active",
    sdp_id: "800454753",
    meter_id: "10931299",
  },
  {
    id: 3,
    except_date: "7/7/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
  {
    id: 4,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
  {
    id: 5,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
  {
    id: 6,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
  {
    id: 7,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
  {
    id: 8,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
  {
    id: 9,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
  {
    id: 10,
    except_date: "7/6/2020",
    except_time: "11:00:00",
    except_type: "Missing Intervals",
    status: "NA",
    sdp_id: "800454753",
    meter_id: "10931298",
  },
];

function VEEExceptionsDetail() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Opt Out Customers Detail
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Meter ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Exception Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">SDP ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.meter_id}</TableCell>
                  <TableCell>
                    <DayJS format="YYYY-MM-DD">{row.except_date}</DayJS>
                  </TableCell>
                  <TableCell>{row.except_time}</TableCell>
                  <TableCell>{row.except_type}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.sdp_id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

export default VEEExceptionsDetail;
