import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";
import { getDataFromApi } from "../../services/rest-api.service";

function AMIKPIs() {
  const [loading, setLoading] = useState(true);
  const [seriesData, setSeriesData] = useState([
    // {
    //   name: "Missed Read",
    //   data: [137, 0, 0, 26],
    // },
  ]);

  const [AMIApprovedData, setAMIApprovedData] = useState({
    series: [44, 555],
    options: {
      chart: {
        type: "donut",
      },
    },
  });
  const [AMIBilledData, setAMIBilledData] = useState({
    series: [44, 555],
    options: {
      chart: {
        type: "donut",
      },
    },
  });
  useEffect(() => {
    getDataFromApi("apiForMissReadAge").then((data) => {
      console.log(data, Object.values(data[0]));
      // const seriesData = Object.values(data[0]);
      setSeriesData([
        {
          name: "Missed Read",
          data: Object.values(data[0]),
        },
      ]);
      setLoading(false);
    });
    setAMIApprovedData({
      series: [70152, 4848],
      options: {
        chart: {
          width: 200,
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
        labels: ["Actuals", "Target"],
        dataLabels: {
          show: false,
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },
        legend: {
          show: false,
        },
        theme: {
          palette: "palette2",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });
    setAMIBilledData({
      series: [70152, 4848],
      options: {
        chart: {
          width: 200,
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
        labels: ["Actuals", "Target"],
        dataLabels: {
          show: false,
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },
        legend: {
          show: false,
        },
        theme: {
          palette: "palette2",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
          style={{
            padding: "0px",
          }}
        >
          <Grid item xs>
            <MA4UChart
              title="AMI Approved"
              type="donut"
              options={AMIApprovedData.options}
              series={AMIApprovedData.series}
              height="180"
            />
          </Grid>
          <Grid item xs>
            <MA4UChart
              title="AMI Billed"
              type="donut"
              options={AMIBilledData.options}
              series={AMIBilledData.series}
              height="180"
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default AMIKPIs;
