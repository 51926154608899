import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

function PendingOptOutSvcOrderAging() {
  const [loading, setLoading] = useState(true);
  const [optOutAgingChartData, setOptOutAgingChartData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    setOptOutAgingChartData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ["Day 1-3", "Day 4-5", "Day 6-7", "Day 7-plus"],
        },
      },
      series: [
        {
          name: "Missed Read",
          data: [105, 234, 35, 98],
        },
      ],
    });
    setTimeout(() => {
      setLoading(false);
      ApexCharts.exec("attribStatusChart", "toggleSeries", "Matching");
    }, 1000);
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Pending Opt Out Svc Order - Aging"
          type="bar"
          options={optOutAgingChartData.options}
          series={optOutAgingChartData.series}
          height="300"
        />
      )}
    </div>
  );
}

export default PendingOptOutSvcOrderAging;
