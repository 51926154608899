import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: 400,
    // overflow: auto,
  },
});

const rows = [
  {
    SDPID: "10090323843432",
    METERID: "123453",
    ACC_NUM: "01123213",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Clean",
    DISP_CATEGORY: "Clean",
    DESC: "Found and Left",
    DIVERSIONED: "N",
    NOTES: "Notes",
  },
  {
    SDPID: "10090323843258",
    METERID: "439247",
    ACC_NUM: "536342",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Diversion",
    DISP_CATEGORY: "Diversion",
    DESC: "Found Diversion",
    DIVERSIONED: "Y",
    NOTES: "Notes",
  },
  {
    SDPID: "10090323736473",
    METERID: "546833",
    ACC_NUM: "01987313",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Clean",
    DISP_CATEGORY: "Clean",
    DESC: "Found and Left",
    DIVERSIONED: "N",
    NOTES: "Notes",
  },
  {
    SDPID: "10090323854318",
    METERID: "765499",
    ACC_NUM: "01129987",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Meter Resolution",
    DISP_CATEGORY: "Meter Resolution",
    DESC: "Issue with the Meter",
    DIVERSIONED: "N",
    NOTES: "Notes",
  },
  {
    SDPID: "10090323843412",
    METERID: "454342",
    ACC_NUM: "01127865",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Diversion",
    DISP_CATEGORY: "Diversion",
    DESC: "Found Diversion",
    DIVERSIONED: "Y",
    NOTES: "Notes",
  },
  {
    SDPID: "10090323854398",
    METERID: "765499",
    ACC_NUM: "01129987",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Meter Resolution",
    DISP_CATEGORY: "Meter Resolution",
    DESC: "Issue with the Meter",
    DIVERSIONED: "N",
    NOTES: "Notes",
  },
  {
    SDPID: "10094783643482",
    METERID: "451232",
    ACC_NUM: "02211865",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Diversion",
    DISP_CATEGORY: "Diversion",
    DESC: "Found Diversion",
    DIVERSIONED: "Y",
    NOTES: "Notes",
  },
  {
    SDPID: "10090389764338",
    METERID: "124321",
    ACC_NUM: "01190987",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Meter Resolution",
    DISP_CATEGORY: "Meter Resolution",
    DESC: "Issue with the Meter",
    DIVERSIONED: "N",
    NOTES: "Notes",
  },
  {
    SDPID: "10076753843492",
    METERID: "898788",
    ACC_NUM: "01765465",
    CREATION_GROUP: "Disconnect",
    DISP_GROUP: "Diversion",
    DISP_CATEGORY: "Diversion",
    DESC: "Found Diversion",
    DIVERSIONED: "Y",
    NOTES: "Notes",
  },
];

function DiversionDispositionWorklist() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card>
      <CardContent>
        {/* <Typography color="textSecondary" gutterBottom>
          Diversion Disposition Worklist
        </Typography> */}
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>SDP ID</TableCell>
                <TableCell>Meter ID</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>Disposition Group</TableCell>
                <TableCell>Disposition Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Found Diversioned</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.SDPID}>
                  <TableCell>{row.SDPID}</TableCell>
                  <TableCell>{row.METERID}</TableCell>
                  <TableCell>{row.ACC_NUM}</TableCell>
                  <TableCell>{row.DISP_GROUP}</TableCell>
                  <TableCell>{row.DISP_CATEGORY}</TableCell>
                  <TableCell>{row.DESC}</TableCell>
                  <TableCell>{row.DIVERSIONED}</TableCell>
                  <TableCell>{row.NOTES}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

export default DiversionDispositionWorklist;
