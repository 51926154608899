import React from "react";
import Chart from "react-apexcharts";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function MA4UChartDetail(props) {
  const classes = useStyles();

  return (
    <div>
      <Card>
        <CardContent>
          <Typography component={'div'} color="textPrimary" gutterBottom>
            {props.title}
          </Typography>
          
              <Chart
              options={props.options}
              series={props.series}
              type={props.type}
              height={props.height ? props.height : '300'}
            />
          
        </CardContent>
      </Card>
    </div>
  );
}

export default MA4UChartDetail;
