import axios from "axios";

const instance = axios.create({
  //baseURL: "https://smartmeteranalytics-prod.uc.r.appspot.com/apis",
  baseURL: "https://bcoe1pi44l.execute-api.us-east-1.amazonaws.com/dev",
  headers: {
    'x-api-key': 'MROidumJQ4a1Q4q73LgFK97vem1d7zAA99BOo5cz'
  },
});

export default instance;
