/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c3f25a8c-c935-44d4-84f8-bdb821997ba8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_m8qSnm0rt",
    "aws_user_pools_web_client_id": "tvsi78ttlnlai1gquqka6opqi",
    "oauth": {}
};


export default awsmobile;
