import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { toast } from 'react-toastify';

import { flexbox } from '@material-ui/system';

import ConnectionStatus from "./ConnectionStatus";
import MDReconMissingInstalledMeterDetail from "./MDReconMissingInstalledMeterDetail";
import MDReconMissingInstalledMeter from "./MDReconMissingInstalledMeter";
import AttributeStatus from "./AttributeStatus";
import AttributeStatusDetailChart from "./AttributeStatusDetailChart";
import AttributeStatusDetail from "./AttributeStatusDetail";
import MDReconKPI from "./MDReconKPI";
import MDReconMasterDataRestore from "./MDReconMasterDataRestore";
import MA4UKPISimple from "../shared/MA4UKPISimple";
import ConnectionStatusDetail from "./ConnectionStatusDetail";
import MDReconOutOfSyncDetail from "./MDReconOutOfSyncDetail";
import { getDataFromApi } from "../../services/rest-api.service";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  checkbox: {
    width: '50ch'
  },
}));

function MDRecon() {
  const [value, setValue] = React.useState(0);
  const [commodity, setCommodity] = React.useState(10);
  const [division, setSetDivision] = React.useState(10);
  const [customerClass, setCustomerClass] = React.useState("All");
  const [billingClass, setBillingClass] = React.useState("All");
  const [meterID, setMeterID] = React.useState("");
  const [lastAssessmentTimestamp, setLastAssessmentTimestamp] = React.useState("");
  const [outOfSyncCount, setOutOfSyncCount] = React.useState(0);
  const [checkedDetail, setCheckedDetail] = React.useState(false);
  const [kpiClicked, setKPIClicked] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [reportDetailName, setReportDetailName] = React.useState("");
  const classes = useStyles();

  useEffect(() => {
    getDataFromApi("apiForLastAssessmentTimestamp").then((data) => {
      setLastAssessmentTimestamp(JSON.parse(data.body));
    });

    if (billingClass === "All")
      getDataFromApi("apiForOutOfSyncCount", { division: division }).then((data) => {
        setOutOfSyncCount(JSON.parse(data.body));
      });
    else
      getDataFromApi("apiForOutOfSyncCount", { division: division, billingClass: billingClass }).then((data) => {
        setOutOfSyncCount(JSON.parse(data.body));
      });

  }, [division, billingClass]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckDetailChange = (event, newValue) => {
    setCheckedDetail(newValue);
  };

  const handleMeterIDChange = (event, newValue) => {
    setMeterID(newValue);
  };

  const handleKPIClick = (event, newValue) => {
    setShowDetail(true);
    setReportDetailName("Out Of Sync Report");
  };

  const handleCommodityChange = (event) => {
    setCommodity(event.target.value);
  };

  const handleCustomerClassChange = (event) => {
    setCustomerClass(event.target.value);
  };

  const setDetailReportName = (value) => {

    setReportDetailName(value);
    if ([
      "Meter",
      "Location",
      "Rate",
      "Register",
      "Account",
      "Profile"
    ].includes(value)) {
      if ((value === "Register" && reportDetailName === "Register") || (value === "Profile" && reportDetailName === "Profile"))
        setShowDetail(true);
      else
        setCheckedDetail(true);
    }
    else {
      setShowDetail(true);
    }
    if ([
      "Install Date",
      "Device Location",
      "Meter Size",
      "Manufacturer",
      "Material",
      "AMCG Group",
      "Manuf. Serial No.",
      "AMS",
      "Register Group",
      "Rate Category",
      "Billing Class",
      "MRU",
      "Cycle",
      "Contract",
      "Contract Account",
      "Move In Date",
      "Move Out Date",
      "Premise",
      "Connection Object",
      "City",
      "State",
      "Post Code",
      "Street",
      "House Number",
      "Room",
      "Timezone",
      "Time Zone",
      "Register",
      "Register Code",
      "Register Type",
      "Register Factor",
      "Places Before Decimal",
      "Places After Decimal",
      "Register Category",
      "Unit",
      "Second Voltage",
      "Second Current",
      "Primary Current",
      "Primary Voltage",
      "Interval Length",
      "Profile To Date",
      "Profile Start Date",
      "Profile",
      "Profile From Date"
    ].includes(value))
      console.log('*' + value + "*");
  };

  const handleApply = () => {
    //toast("Click Apply. To be implemented!");
    setSetDivision(commodity);
    setBillingClass(customerClass);
  }

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel id="commodity-label">Commodity*</InputLabel>
          <Select
            labelId="commodity-label"
            id="commodity-select"
            value={commodity}
            onChange={handleCommodityChange}
          >
            <MenuItem value={10}>Electricity</MenuItem>
            <MenuItem value={20}>Water</MenuItem>
            <MenuItem value={30}>Gas</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="customer-class-label">Customer Class</InputLabel>
          <Select
            labelId="customer-class-label"
            id="customer-class-select"
            value={customerClass}
            onChange={handleCustomerClassChange}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"RES"}>RES</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField id="txtMeterID"
            onChange={handleMeterIDChange}
            label="Meter ID"
            value={meterID}
          />
        </FormControl>
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              defaultChecked
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}>
            </Checkbox>
          }
          label="Stacked View by Meter w./wo. Open Tickets"
        />
        <FormControl className={classes.formControl}>
          <Button variant="outlined" color="primary" onClick={handleApply}>
            Apply
          </Button>
        </FormControl>

      </form>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example"
        >
          <Tab label="Meter Master Data Reconciliation Analysis" {...a11yProps(0)} />
          <Tab label="Master Data Restore by Auto Sync" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {showDetail ?
          <>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<ArrowBackIcon />}
              onClick={() => { setShowDetail(false) }}
            >
              Back
            </Button>
            {
              reportDetailName === "Out Of Sync Report" &&
              <div>
                Meter with Out of Sync Master Data Details
                <MDReconOutOfSyncDetail apiCode="apiForOutOfSyncCountDetail" />
              </div>
            }
            {
              (reportDetailName === "Load Status" || reportDetailName === "Power Status") &&
              <div>
                Connection Status Details - {reportDetailName}
                <ConnectionStatusDetail apiCode="apiForConnectionStatusDetail" subMetric={reportDetailName} />
              </div>
            }
            {
              (reportDetailName === "MDM" || reportDetailName === "HES" || reportDetailName === "CIS") &&
              <div>
                Missing Installed Meter Details - Missing in {reportDetailName}
                <MDReconMissingInstalledMeterDetail apiCode="apiForMissingInstalledMeterDetail" missingSystem={reportDetailName} />
              </div>

            }
            {
              [
                "Install Date",
                "Device Location",
                "Meter Size",
                "Manufacturer",
                "Material",
                "AMCG Group",
                "Manuf. Serial No.",
                "AMS",
                "Register Group",
                "Rate Category",
                "Billing Class",
                "MRU",
                "Cycle",
                "Contract",
                "Contract Account",
                "Move In Date",
                "Move Out Date",
                "Premise",
                "Connection Object",
                "City",
                "State",
                "Post Code",
                "Street",
                "House Number",
                "Room",
                "Timezone",
                "Time Zone",
                "Register",
                "Register Code",
                "Register Type",
                "Register Factor",
                "Places Before Decimal",
                "Places After Decimal",
                "Register Category",
                "Unit",
                "Second Voltage",
                "Second Current",
                "Primary Current",
                "Primary Voltage",
                "Interval Length",
                "Profile To Date",
                "Profile Start Date",
                "Profile",
                "Profile From Date"
              ].includes(reportDetailName) &&
              <AttributeStatusDetail apiCode="apiForSubAttributeDetail" subMetric={reportDetailName} />
            }
          </>
          :
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              display="flex"
              spacing={2}
            >
              {
                kpiClicked ? (
                  <>

                  </>
                )
                  :
                  <>
                    <Grid item xs={12} sm={12} md={2}>
                      <div onClick={handleKPIClick}>
                        <MDReconKPI total={outOfSyncCount} value1={23} value2={0} value3={0} />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <ConnectionStatus setDetailReportName={setDetailReportName} division={division} billingClass={billingClass} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <MDReconMissingInstalledMeter setDetailReportName={setDetailReportName} division={division} billingClass={billingClass} />
                    </Grid>
                    {checkedDetail && ["Meter", "Location", "Rate", "Account", "Register", "Profile"].includes(reportDetailName) ?
                      <Grid item xs={12} sm={12} md={11}>
                        <AttributeStatusDetailChart setDetailReportName={setDetailReportName} metric={reportDetailName} />
                      </Grid>
                      :
                      <>
                        <Grid item xs={12} sm={12} md={2} alignItems='stretch'>
                          <MA4UKPISimple
                            value={lastAssessmentTimestamp}
                            label={"Last auto synchronization initiation timestamp"}
                            color="info.main"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                          <AttributeStatus setDetailReportName={setDetailReportName} division={division} billingClass={billingClass} />
                        </Grid>
                      </>
                    }
                    <Grid item xs={12} sm={12} md={1}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="toggleDetail"
                            color="primary"
                            checked={checkedDetail}
                            onChange={handleCheckDetailChange}
                          />
                        }
                        label="Attribute Details"
                      />
                    </Grid>
                  </>
              }
            </Grid>
          </>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MDReconMasterDataRestore apiCode="apiForMeterSentCount" timestamp={"2021-03-01 10:10:20 CST"} division={division} />
      </TabPanel>
    </div>
  );
}

export default MDRecon;
