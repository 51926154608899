import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import DetectionMethodChart from "./DetectionMethodChart";
import DiversionDispositionChart from "./DiversionDispositionChart";
import { Grid } from "@material-ui/core";
import SuspectListReport from "./SuspectListReport";
import SDPHistory from "./SDPHistory";
import DiversionDispositionWorklist from "./DiversionDispositionWorklist";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function RevenueProtection() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example"
        >
          <Tab label="Suspect List" {...a11yProps(0)} />
          <Tab label="SDP History" {...a11yProps(1)} />
          <Tab label="Disposition Results" {...a11yProps(2)} />
          <Tab label="Detection Method Comparision" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SuspectListReport />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SDPHistory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DiversionDispositionWorklist />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs md>
            <DiversionDispositionChart />
          </Grid>
          <Grid item xs md>
            <DetectionMethodChart />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}

export default RevenueProtection;
