import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

function OptOutCustomersTrend() {
  const [loading, setLoading] = useState(true);
  const [optOutTrendChartData, setOptOutTrendChartData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    setOptOutTrendChartData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      series: [
        {
          name: "2019",
          data: [40, 55, 60, 50, 30, 50, 50, 32, 50, 20, 50, 18],
        },
        {
          name: "2020",
          data: [34, 38, 50, 50, 37, 50, 50, 49, 50, 50, 100, 50],
        },
      ],
    });
    setTimeout(() => {
      setLoading(false);
      ApexCharts.exec("attribStatusChart", "toggleSeries", "Matching");
    }, 1000);
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Opt Out Customers By Month"
          type="line"
          options={optOutTrendChartData.options}
          series={optOutTrendChartData.series}
          height="300"
        />
      )}
    </div>
  );
}

export default OptOutCustomersTrend;
