import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { blue, green, pink, red, yellow } from "@material-ui/core/colors";

import Grid from "@material-ui/core/Grid";

import Container from "@material-ui/core/Container";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import OptOutCustomersTrend from "./OptOutCustomersTrend";
import PendingOptOutSvcOrderAging from "./PendingOptOutSvcOrderAging";
import OptOutCustomersDetail from "./OptOutCustomersDetail";

const useStyles = makeStyles((theme) => ({
  // root: { display: "flex" },
  container: {
    padding: theme.spacing(2.5),
    // paddingBottom: theme.spacing(4),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  success: {
    color: "#fff",
    backgroundColor: green[500],
  },
  yellow: {
    color: "#fff",
    backgroundColor: yellow[800],
  },
  danger: {
    color: "#fff",
    backgroundColor: red[800],
  },
  neutral: {
    color: "#fff",
    backgroundColor: blue[600],
  },
}));

const kpiData = [
  {
    icon: <PeopleIcon />,
    title: "Total Customers",
    value: 10000,
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: "neutral",
    isNumber: true,
    tooltip: `Theshold value is 10000`,
    isPercent: false,
  },
  {
    icon: <SupervisorAccountIcon />,
    title: "Active Customers",
    value: 9350,
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: "success",
    isNumber: true,
    tooltip: `Theshold value is 10000`,
    isPercent: false,
  },
  {
    icon: <PersonAddDisabledIcon />,
    title: "Opt-Out Customers",
    value: 650,
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: "neutral",
    isNumber: true,
    tooltip: `Theshold value is 10000`,
    isPercent: false,
  },
  {
    icon: <CheckCircleOutlineIcon />,
    title: "Avg Time for Opt Out Svc Orders Completion",
    value: "1.5 Days",
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: 10000 < 9999 ? "danger" : "success",
    isNumber: false,
    tooltip: `Theshold value is 10000`,
    isPercent: true,
  },
  {
    icon: <CancelIcon />,
    title: "Outstanding Opt Out Svc Orders",
    value: 502,
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: 10000 < 10001 ? "danger" : "success",
    isNumber: false,
    tooltip: `Theshold value is 10000`,
    isPercent: true,
  },
];

function OptOutReport() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {kpiData.map((kpi) => (
            <Grid item xs md key={kpi.title}>
              <Card>
                <CardHeader
                  avatar={
                    loading ? (
                      <Skeleton
                        animation="wave"
                        variant="circle"
                        width={40}
                        height={40}
                      />
                    ) : (
                      <Avatar
                        className={
                          kpi.state === "success"
                            ? classes.success
                            : kpi.state === "neutral"
                            ? classes.neutral
                            : classes.danger
                        }
                      >
                        {kpi.icon}
                      </Avatar>
                    )
                  }
                  title={
                    loading ? (
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    ) : (
                      kpi.title
                    )
                  }
                  subheader={
                    loading ? (
                      <Skeleton animation="wave" height={10} width="40%" />
                    ) : (
                      kpi.value
                    )
                  }
                ></CardHeader>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container maxWidth="xl" className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6}>
            <OptOutCustomersTrend />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <PendingOptOutSvcOrderAging />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs>
            <OptOutCustomersDetail />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default OptOutReport;
