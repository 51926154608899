import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";
import { getDataFromApi } from "../../services/rest-api.service";

function MissReadAging() {
  const [loading, setLoading] = useState(true);
  const [seriesData, setSeriesData] = useState([
    // {
    //   name: "Missed Read",
    //   data: [137, 0, 0, 26],
    // },
  ]);

  const [missReadAgingData, setMissReadAgingData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    getDataFromApi("apiForMissReadAge").then((data) => {
      /*
      console.log(data, Object.values(data[0]));
      const seriesData = Object.values(data[0]);
      setSeriesData([
        {
          name: "Missed Read",
          data: Object.values(data[0]),
        },
      ]);
      */
      setLoading(false);
    });
    setMissReadAgingData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ["Day 1-3", "Day 4-5", "Day 6-7", "Day 7-plus"],
        },
      },
    });
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Register Missed Read Meter Count - Aging"
          type="bar"
          options={missReadAgingData.options}
          series={seriesData}
          height="250"
        />
      )}
    </div>
  );
}

export default MissReadAging;
