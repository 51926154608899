import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Settings() {
  const light = {
    palette: {
      type: "light",
    },
  };

  const dark = {
    palette: {
      type: "dark",
    },
  };
  const [theme, setTheme] = useState(true);
  //   const icon = !theme ? <Brightness7Icon /> : <Brightness3Icon />;
  const appliedTheme = createMuiTheme(theme ? light : dark);
  // const theme = React.useMemo(
  //     () =>
  //       createMuiTheme({
  //         palette: {
  //           type: isDarkTheme ? "dark" : "light",
  //         },
  //       }),
  //     [isDarkTheme]
  //   );
  // const handleToggleTheme = (event) => {
  //     let theme =
  //       localStorage.getItem("themeForMA4U") === "MA4UDark" ? true : false;
  //     if (!theme) {
  //       setDarkTheme(true);
  //       localStorage.setItem("themeForMA4U", "MA4UDark");
  //       window.Apex = {
  //         chart: {
  //           foreColor: "#ccc",
  //         },
  //         theme: { mode: "dark" },
  //       };
  //     } else {
  //       setDarkTheme(false);
  //       localStorage.setItem("themeForMA4U", "MA4ULight");
  //       window.Apex = {
  //         chart: {
  //           foreColor: "#ccc",
  //         },
  //         theme: { mode: "light" },
  //       };
  //     }
  //   };
  const classes = useStyles();

  return (
    <div>
      <ThemeProvider theme={appliedTheme}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>xs=12</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>xs=12 sm=6</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>xs=12 sm=6</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>xs=6 sm=3</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>xs=6 sm=3</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>xs=6 sm=3</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>xs=6 sm=3</Paper>
            </Grid>
          </Grid>
        </div>
        <Button variant="contained">Default</Button>
        <Button variant="contained" color="primary">
          Primary
        </Button>
        <Button variant="contained" color="secondary">
          Secondary
        </Button>
        <Button variant="contained" disabled>
          Disabled
        </Button>
        <Button variant="contained" color="primary" href="#contained-buttons">
          Link
        </Button>
        <button
          onClick={() => {
            console.log("ok");
            setTheme(!theme);
          }}
        >
          Toggle Theme
        </button>
        Settings
      </ThemeProvider>
    </div>
  );
}

export default Settings;
