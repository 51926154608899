import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { blue, green, pink, red, yellow } from "@material-ui/core/colors";

import Grid from "@material-ui/core/Grid";
import PeopleIcon from "@material-ui/icons/People";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import Container from "@material-ui/core/Container";
import RateReviewIcon from "@material-ui/icons/RateReview";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Skeleton from "@material-ui/lab/Skeleton";

import MA4UKPI from "../shared/MA4UKPI";
import MissReadTrend from "./MissReadTrend";
import MissReadAging from "./MissReadAging";
import MissReadDetail from "./MissReadDetail";
import { getDataFromApi } from "../../services/rest-api.service";

const useStyles = makeStyles((theme) => ({
  // root: { display: "flex" },
  container: {
    paddingTop: theme.spacing(2.5),
    // paddingBottom: theme.spacing(4),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  success: {
    color: "#fff",
    backgroundColor: green[500],
  },
  yellow: {
    color: "#fff",
    backgroundColor: yellow[800],
  },
  danger: {
    color: "#fff",
    backgroundColor: red[800],
  },
  neutral: {
    color: "#fff",
    backgroundColor: blue[600],
  },
}));

const kpiData = [
  {
    icon: <PeopleIcon />,
    title: "Total Customers",
    value: 10000,
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: "neutral",
    isNumber: true,
    tooltip: `Theshold value is 10000`,
    isPercent: false,
  },
  {
    icon: <AvTimerIcon />,
    title: "Active Meters",
    value: 10000,
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: "success",
    isNumber: true,
    tooltip: `Theshold value is 10000`,
    isPercent: false,
  },
  {
    icon: <AvTimerIcon />,
    title: "Total Meters",
    value: 10000,
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: "neutral",
    isNumber: true,
    tooltip: `Theshold value is 10000`,
    isPercent: false,
  },
  {
    icon: <RateReviewIcon />,
    title: "Register Read Rate",
    value: "98.35%",
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: 10000 < 9999 ? "danger" : "success",
    isNumber: false,
    tooltip: `Theshold value is 10000`,
    isPercent: true,
  },
  {
    icon: <RateReviewIcon />,
    title: "Interval Read Rate",
    value: "98.63%",
    color: 10000 < 9999 ? "#f44336" : "#ffb300",
    state: 10000 < 10001 ? "danger" : "success",
    isNumber: false,
    tooltip: `Theshold value is 10000`,
    isPercent: true,
  },
];

function MissReadSummary() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDataFromApi("apiForMeterCount").then((data) => {
      console.log(data);
      setLoading(false);
    });
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {kpiData.map((kpi) => (
            <Grid item xs sm md key={kpi.title}>
              {loading ? (
                <Card>
                  <CardHeader
                    avatar={
                      <Skeleton
                        animation="wave"
                        variant="circle"
                        width={40}
                        height={40}
                      />
                    }
                    title={
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={10} width="40%" />
                    }
                  ></CardHeader>
                </Card>
              ) : (
                <MA4UKPI
                  title={kpi.title}
                  value={kpi.value}
                  icon={kpi.icon}
                  state={
                    kpi.state === "success"
                      ? classes.success
                      : kpi.state === "neutral"
                      ? classes.neutral
                      : classes.danger
                  }
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container maxWidth="xl" className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6}>
            <MissReadTrend />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MissReadAging />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs>
            <MissReadDetail />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default MissReadSummary;
