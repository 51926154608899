import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

function DiversionDispositionChart() {
  const [loading, setLoading] = useState(true);
  const [
    diversionDispositionChartData,
    setDiversionDispositionChartData,
  ] = useState({
    options: {},
    series: [],
  });

  useEffect(() => {
    setDiversionDispositionChartData({
      options: {
        chart: {
          height: 350,
          type: "line",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        theme: {
          mode: "light",
          palette: "palette7",
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
        xaxis: {
          categories: [
            "Oct-19",
            "Nov-19",
            "Dec-19",
            "Jan-20",
            "Feb-20",
            "Mar-20",
            "Apr-20",
            "May-20",
            "Jun-20",
            "Jul-20",
            "Aug-20",
            "Sep-20",
          ],
        },
      },
      series: [
        {
          name: "Clean",
          data: [28, 29, 33, 36, 32, 32, 33, 43, 32, 11, 23, 32],
        },
        {
          name: "Review",
          data: [12, 11, 14, 18, 17, 13, 13, 28, 29, 33, 36, 32],
        },
        {
          name: "Meter Resolution",
          data: [18, 19, 23, 26, 42, 52, 23, 12, 11, 14, 18, 17],
        },
        {
          name: "Tamper",
          data: [15, 19, 13, 58, 27, 33, 43, 17, 13, 13, 28, 29],
        },
        {
          name: "Diversion",
          data: [26, 42, 52, 23, 12, 32, 33, 43, 32, 11, 23, 0],
        },
      ],
    });
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);
  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Diversion Disposition By Month"
          type="bar"
          options={diversionDispositionChartData.options}
          series={diversionDispositionChartData.series}
        />
      )}
    </div>
  );
}

export default DiversionDispositionChart;
