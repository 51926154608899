import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

function ChartSkeleton() {
  return (
    <div>
      <Card>
        <CardContent>
          <Typography component={"div"} color="textPrimary" gutterBottom>
            <Skeleton animation="wave" height={30} width="80%" />
          </Typography>
          <Skeleton animation="wave" height={200} width="100%" />
        </CardContent>
      </Card>
    </div>
  );
}

export default ChartSkeleton;
