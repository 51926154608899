import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

function DetectionMethodChart() {
  const [loading, setLoading] = useState(true);
  const [detectionMethodChartData, setDetectionMethodChartData] = useState({
    options: {},
    series: [],
  });

  useEffect(() => {
    setDetectionMethodChartData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        theme: {
          mode: "light",
          palette: "palette5",
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
        xaxis: {
          categories: [
            "Oct-19",
            "Nov-19",
            "Dec-19",
            "Jan-20",
            "Feb-20",
            "Mar-20",
            "Apr-20",
            "May-20",
            "Jun-20",
            "Jul-20",
            "Aug-20",
            "Sep-20",
          ],
        },
      },
      series: [
        {
          name: "No Read Automation",
          data: [28, 29, 33, 36, 32, 32, 33, 43, 32, 11, 23, 32],
        },
        {
          name: "Tamper",
          data: [12, 11, 14, 18, 17, 13, 13, 28, 29, 33, 36, 32],
        },
        {
          name: "Disconnect",
          data: [18, 19, 23, 26, 42, 52, 23, 12, 11, 14, 18, 17],
        },
        {
          name: "Load Side Volatage",
          data: [15, 19, 13, 58, 27, 33, 43, 17, 13, 13, 28, 29],
        },
        {
          name: "Total",
          data: [15, 19, 13, 58, 17, 13, 13, 28, 29, 27, 33, 43],
        },
      ],
    });
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);
  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Detection Method By Month"
          type="line"
          options={detectionMethodChartData.options}
          series={detectionMethodChartData.series}
        />
      )}
    </div>
  );
}

export default DetectionMethodChart;
