import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

function VEEExceptionTrend() {
  const [loading, setLoading] = useState(true);
  const [VEEExceptionTrendChartData, setVEEExceptionTrendChartData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    setVEEExceptionTrendChartData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "7/6/2020",
            "7/7/2020",
            "7/8/2020",
            "7/12/2020",
            "7/13/2020",
            "7/14/2020",
            "7/15/2020",
          ],
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
      },
      series: [
        {
          name: "Missing Intervals",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Meter Update Exception",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
        {
          name: "Device Not Installed Exception",
          data: [18, 19, 23, 26, 42, 52, 23],
        },
        {
          name: "Device Not Found Exception",
          data: [15, 19, 13, 58, 27, 33, 43],
        },
      ],
    });
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="VEE Exceptions Trend"
          type="area"
          options={VEEExceptionTrendChartData.options}
          series={VEEExceptionTrendChartData.series}
          height="300"
        />
      )}
    </div>
  );
}

export default VEEExceptionTrend;
