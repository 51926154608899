import React from "react";
import axios from "./axios";
import ApiConfig from "../config/default.json";
import { toast } from 'react-toastify';

export async function getDataFromApi(apiCode, params = {}) {
  const url = ApiConfig.rest_endpoint_config[apiCode];
  try {
    const response = await axios.get(url,
      { params: params }
    );
    return response.data;
  } catch (error) {
    toast(error);
  }
}

export function useFetch(apiCode) {
  const url = ApiConfig.rest_endpoint_config[apiCode];
  React.useEffect(() => {
    async function fetchData() {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    }
    fetchData();
  }, [url]);
}
