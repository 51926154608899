import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';

import MA4UKPISimple from "../shared/MA4UKPISimple";
import { getDataFromApi } from "../../services/rest-api.service";

function MDReconMasterDataRestore(props) {
  const [meterSentCount, setMeterSentCount] = useState({
    meter_sent_total_cnt: 137,
    meter_sent_success_cnt: 100,
    meter_sent_error_cnt: 37
  })
  useEffect(() => {
    const division = props.division;
    const url = props.apiCode;
    if (url)
      getDataFromApi(url, {division: division}).then((data) => {
        const parsed_data = JSON.parse(data.body);
        setMeterSentCount(parsed_data);
      });
  }, [props.apiCode, props.division]);

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <MA4UKPISimple value={meterSentCount.meter_sent_total_cnt} label={"Total count of meter sent through auto sync"} color="primary.main" />
        </Grid>
        <Grid item xs={3}>
          <MA4UKPISimple value={meterSentCount.meter_sent_success_cnt} label={"Count of meter with sucessful response"} color="success.main" />
        </Grid>
        <Grid item xs={3}>
          <MA4UKPISimple value={meterSentCount.meter_sent_error_cnt} label={"Count of meter with error response"} color="error.main" />
        </Grid>
        <Grid item xs={3}>
          <MA4UKPISimple value={props.timestamp} label={"Last auto synchronization initiation timestamp"} color="info.main" />
        </Grid>
      </Grid>
    </div>
  );
}

export default MDReconMasterDataRestore;
