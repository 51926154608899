import { Component } from "react";
import "./App.css";
import Layout from "./components/Layout";
import Auth from "@aws-amplify/auth";
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";

Auth.configure(awsconfig);

class App extends Component {
  render() {
    return (
      <AmplifyAuthenticator>
        <div>
        <Layout />
      </div>
      </AmplifyAuthenticator>
      
    );
  }
}

export default App;
