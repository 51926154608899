import React, { useEffect, useState } from "react";
import Auth from "@aws-amplify/auth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import clsx from "clsx";
import {
  fade,
  makeStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import RefreshIcon from "@material-ui/icons/Refresh";
import AccessTime from "@material-ui/icons/AccessTime";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import MapIcon from "@material-ui/icons/Map";
import PollIcon from "@material-ui/icons/Poll";
import SyncIcon from "@material-ui/icons/Sync";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import ReportIcon from "@material-ui/icons/Report";
import TuneIcon from '@material-ui/icons/Tune';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import MissReadSummary from "./miss-read-summary/MissReadSummary";
import MDRecon from "./md-recon/MDRecon";
import OptOutReport from "./opt-out-report/OptOutReport";
import VEEDashboard from "./vee-dashboard/VEEDashboard";
import RevenueProtection from "./revenue-protection/RevenueProtection";
import AMIReport from "./ami-report/AMIReport";
import MapView from "./map-view/MapView";
import Settings from "./Settings";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },

  activeNavStyle: {
    color: "#202020",
    backgroundColor: "lightgrey",
    textDecoration: "none",
  },
}));

function Layout() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isDarkTheme, setDarkTheme] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [user, setUser] = useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    localStorage.setItem(
      "themeForMA4U",
      isDarkTheme ? "MA4UDark" : "MA4ULight"
    );
  }, [isDarkTheme]);

  const sideNavigationData = [
    {
      text: "Miss Reads Summary",
      to: "/MissReadsSummary",
      icon: <BarChartIcon />,
      component: <MissReadSummary />,
    },
    {
      text: "Meter Data Recon",
      to: "/MeterDataRecon",
      icon: <SyncIcon />,
      component: <MDRecon />,
    },
    {
      text: "Opt-Out Reports",
      to: "/OptOutReports",
      icon: <ZoomOutMapIcon />,
      component: <OptOutReport />,
    },
    {
      text: "VEE Dashboard",
      to: "/VEEDashboard",
      icon: <DashboardIcon />,
      component: <VEEDashboard />,
    },
    {
      text: "Revenue Protection",
      to: "/RevenueProtection",
      icon: <ReportIcon />,
      component: <RevenueProtection />,
    },
    {
      text: "AMI Implementation",
      to: "/AMIImplementation",
      icon: <TuneIcon />,
      component: <AMIReport />,
    },
    {
      text: "Map View",
      to: "/MapView",
      icon: <MapIcon />,
      component: <MapView />,
    },
  ];

  const handleSignOut = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);

    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
  }

  const handleToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(menuOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  React.useEffect(() => {
      Auth.currentAuthenticatedUser({
        bypassCache: false  
      }).then(user => setUser(user))
      .catch(err => console.log(err));
  }, [])

  return (
    <div className={classes.root}>
      {/* <ThemeProvider theme={light}> */}
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            align="center"
            noWrap
            className={classes.title}
          >
            MeterAnalytics4U
          </Typography>

          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Reports"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "Search Reports" }}
            />
          </div> */}
          {/* <Searchbox /> */}
          <IconButton aria-label="Refresh Dashboard" color="inherit">
            <RefreshIcon />
          </IconButton>
          {/* <IconButton
              aria-label="Toggle Theme"
              color="inherit"
              onClick={handleToggleTheme}
            >
              <Brightness4Icon />
            </IconButton> */}

          {/* <Tooltip title="Last Refresh Time"> */}
          <IconButton
            aria-label="Last Refresh Time"
            tooltip="Last Refresh Time"
            color="inherit"
          >
            <AccessTime />
          </IconButton>
          {/* </Tooltip> */}
          <IconButton
            ref={anchorRef}
            edge="end"
            aria-label="account of current user"
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Popper open={menuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleMenuClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={handleMenuClose}>
                        Welcome {user.attributes.email}
                      </MenuItem>
                      <MenuItem onClick={handleSignOut}> Sign Out </MenuItem> 
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
        <ToastContainer />
      </AppBar>
      <Router>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {sideNavigationData.map((navItem) => (
              <NavLink
                to={navItem.to}
                // // activeClassName={classes.activeNavStyle}
                activeStyle={{
                  color: "blue",
                  backgroundColor: "lightgrey",
                  textDecoration: "none",
                }}
                style={{ textDecoration: "none" }}
                key={navItem.to}
              >
                <ListItem button>
                  <ListItemIcon>{navItem.icon}</ListItemIcon>
                  <ListItemText primary={navItem.text} />
                </ListItem>
              </NavLink>
            ))}

            <ListItem
              button
              onClick={() => {
                window.open("https://utg-demo-lp4u.uc.r.appspot.com", "_blank");
              }}
            >
              <ListItemIcon>
                <PollIcon />
              </ListItemIcon>
              <ListItemText primary="LP4U" />
            </ListItem>
          </List>
          <Divider />
          {/* <List>
            <NavLink
              to="/Settings"
              // // activeClassName={classes.activeNavStyle}
              activeStyle={{
                color: "blue",
                backgroundColor: "lightgrey",
                textDecoration: "none",
              }}
              style={{ textDecoration: "none" }}
              key="Settings"
            >
              <ListItem button>
                <ListItemIcon>
                  <SettingsInputComponent />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </NavLink>
          </List> */}
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {/* Routing happens here... */}
          <Switch>
            {sideNavigationData.map((navItem) => (
              <Route path={navItem.to} key={navItem.to}>
                {navItem.component}
              </Route>
            ))}
            <Route path="/Settings" key="Settings">
              <Settings />
            </Route>
          </Switch>
        </main>
      </Router>
      {/* </ThemeProvider> */}
    </div>
  );
}

export default Layout;
