import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";
import { getDataFromApi } from "../../services/rest-api.service";

function ServiceOrderAging() {
  const [loading, setLoading] = useState(true);
  const [seriesData, setSeriesData] = useState([
    // {
    //   name: "Missed Read",
    //   data: [137, 0, 0, 26],
    // },
  ]);

  const [serviceOrderAgingData, setServiceOrderAgingData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    getDataFromApi("apiForMissReadAge").then((data) => {
      setSeriesData([
        {
          name: "Count",
          // data: Object.values(data[0]),
          data: [503, 29, 13, 27]
        },
      ]);
      setLoading(false);
    });
    setServiceOrderAgingData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ["Day 1-3", "Day 4-5", "Day 6-7", "Day 7-plus"],
        },
      },
    });
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Service Orders - Aging"
          type="bar"
          options={serviceOrderAgingData.options}
          series={seriesData}
          height="280"
        />
      )}
    </div>
  );
}

export default ServiceOrderAging;
