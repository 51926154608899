import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
  },
  number: {
    textAlign: 'center',
  },
}));

export default function MA4UKPITriple(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography className={classes.number} variant="h5" component="h2">
              <Box color="error.main">{props.value1}</Box>
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              { props.key1 }
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.number} variant="h5" component="h2">
              <Box color="error.main">{props.value2}</Box>
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
            { props.key2 }
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.number} variant="h5" component="h2">
              <Box color="error.main">{props.value3}</Box>
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
            { props.key3 }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Open Tickets
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
