import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

function VEEExceptionRank() {
  const [loading, setLoading] = useState(true);
  const [VEEExceptionChartData, setVEEExceptionChartData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    setVEEExceptionChartData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
            "Invalid Work Status Notification Request",
            "Export Failed",
            "Device Not Found Exception",
            "Invalid Request",
            "Device Not Installed Exception",
            "Configuration Error",
            "Unexpected Processing Error",
            "Invalid Process Error",
            "Missing Intervals",
          ],
        },
      },
      series: [
        {
          name: "Exceptions Count",
          data: [40, 230, 348, 470, 540, 680, 790, 810, 1200],
        },
      ],
    });
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Top 10 VEE Exceptions"
          type="bar"
          options={VEEExceptionChartData.options}
          series={VEEExceptionChartData.series}
          height="300"
        />
      )}
    </div>
  );
}

export default VEEExceptionRank;
