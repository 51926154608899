import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: 400,
    // overflow: auto,
  },
});

const rows = [
  {
    EXCEPTION: "Power Outage Notification",
    TIMESTAMP: "2020-02-01 08:44:22",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Power Restore Notification",
    TIMESTAMP: "2020-02-02 18:44:12",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Tamper Alert",
    TIMESTAMP: "2020-02-02 03:23:41",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Tamper Alert",
    TIMESTAMP: "2020-02-03 09:32:32",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Tamper Alert",
    TIMESTAMP: "2020-02-04 06:44:02",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Power Restore Notification",
    TIMESTAMP: "2020-02-05 03:11:08",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Tamper Alert",
    TIMESTAMP: "2020-02-11 05:54:22",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Power Outage Notification",
    TIMESTAMP: "2020-02-21 08:24:55",
    EXCEPTION_FLAG: "Exception",
  },
  {
    EXCEPTION: "Power Outage Notification",
    TIMESTAMP: "2020-02-24 02:44:22",
    EXCEPTION_FLAG: "Exception",
  },
];

function EventsNExceptionReport() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card>
      <CardContent>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Event / Exception</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Event / Exception Flag</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.TIMESTAMP}>
                  <TableCell>{row.EXCEPTION}</TableCell>
                  <TableCell>{row.TIMESTAMP}</TableCell>
                  <TableCell>{row.EXCEPTION_FLAG}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

export default EventsNExceptionReport;
