import React, { useEffect, useState } from "react";
import useDeepCompareEffect from 'use-deep-compare-effect'
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";

import { getDataFromApi } from "../../services/rest-api.service";

function ConnectionStatus(props) {
  const [loading, setLoading] = useState(true);
  const [connStatusData, setConnStatusData] = useState({
    options: {},
    series: [],
  });

  useEffect(() => {
    const division = props.division ? props.division : 10;
    getDataFromApi("apiForConnectionStatus", { division: division }).then((data) => {
      setConnStatusData({
        options: {
          colors: ['#4CAF50', '#FF9800'],
          chart: {
            id: "connStatusChart",
            stacked: true,
            height: 200,
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                if (config.dataPointIndex === 0) {
                  if (props)
                    props.setDetailReportName("Power Status");
                }
                else {
                  if (props)
                    props.setDetailReportName("Load Status");
                }
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              if (val > 0)
                return val;
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "center",
          },
          theme: {
            mode: "light",
            palette: "palette2",
          },
          subtitle: {
            text: ``,
          },
          noData: {
            text: "Loading...",
          },
          xaxis: {
            categories: ["Power Status", "Load Status"],
          },
        },
        series: JSON.parse(data.body),
      });
      setLoading(false);
    });
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [props.division]);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Mismatched Connection Status"
          type="bar"
          options={connStatusData.options}
          series={connStatusData.series}
          height="200"
        />
      )}

    </div>
  );
}

export default ConnectionStatus;
