import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Sync from "@material-ui/icons/Sync";
import Event from "@material-ui/icons/Event";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CloseIcon from "@material-ui/icons/Close";
import RssFeedIcon from '@material-ui/icons/RssFeed';
import Fab from '@material-ui/core/Fab';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from '@material-ui/core/Link';


import FilterListIcon from "@material-ui/icons/FilterList";
import { getDataFromApi } from "../../services/rest-api.service";
import TicketDetail from "./TicketDetail";
import SAPConfig from "../../config/sap.json";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "meter_number",
    numeric: false,
    disablePadding: true,
    label: "Meter ID",
  },
  {
    id: "billing_class",
    numeric: false,
    disablePadding: true,
    label: "Rate Class",
  },
  {
    id: "cycle",
    numeric: false,
    disablePadding: true,
    label: "Cycle",
  },
  {
    id: "service_order_count",
    numeric: false,
    disablePadding: true,
    label: "Open Service Orders",
  },
  {
    id: "tickets_count",
    numeric: false,
    disablePadding: true,
    label: "Open Tickets",
  },
  {
    id: "auto_sync_status",
    numeric: false,
    disablePadding: true,
    label: "Auto Sync Status",
  },
  {
    id: "ping",
    numeric: false,
    disablePadding: true,
    label: "Ping Status",
  },
  {
    id: "manual_sync_status",
    numeric: false,
    disablePadding: true,
    label: "Manual Sync Status",
  },
  {
    id: "manual_sync_request_time",
    numeric: false,
    disablePadding: true,
    label: "Manual Sync Request Time",
  },
  {
    id: "attribute",
    numeric: false,
    disablePadding: true,
    label: "Attributes",
  },
  {
    id: "cis_value",
    numeric: false,
    disablePadding: true,
    label: "CIS Value",
  },
  {
    id: "mdm_value",
    numeric: false,
    disablePadding: true,
    label: "MDM Value",
  },
  {
    id: "hes_value",
    numeric: false,
    disablePadding: true,
    label: "HES Value",
  },
];

const data_rows = [
  {
    meter_number: "10002244",
    billing_class: "SVS",
    cycle: 1,
    service_order_count: 1,
    tickets_count: 5,
    auto_sync_status: "Error",
    ping_status: null,
    manual_sync_status: null,
    manual_sync_request_time: null,
    attributes: [
      {
        attribute: "Device Location",
        cis_value: 110,
        mdm_value: 111,
        hes_value: 202
      },
      {
        attribute: "Address - House No.",
        cis_value: 3,
        mdm_value: 4,
        hes_value: 8
      }
    ]
  },
  {
    meter_number: "10002245",
    billing_class: "SVS",
    cycle: 1,
    service_order_count: 1,
    tickets_count: 5,
    auto_sync_status: "NA",
    ping_status: null,
    manual_sync_status: null,
    manual_sync_request_time: null,
    attributes: [
      {
        attribute: "Device Location",
        cis_value: 55,
        mdm_value: 22,
        hes_value: 109
      },
      {
        attribute: "Connection Object",
        cis_value: 21,
        mdm_value: 7,
        hes_value: 8
      }
    ]
  }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Create Sync Request">
            <Fab color="primary" size="small" aria-label="Sync">
              <Sync />
            </Fab>
          </Tooltip>
          <Tooltip title="Create Ticket">
            <Fab color="primary" size="small" aria-label="Ticket"
              href="https://utg-webdisp.utegration.com:44304/sap/bc/gui/sap/its/webgui/?~transaction=EMMAC3%20AUFNR-LOW=91234911;" target="_blank"
            >
              <Event />
            </Fab>
          </Tooltip>
          <Tooltip title="Create Service Order">
            <Fab color="primary" size="small" aria-label="Order"
              href="https://utg-webdisp.utegration.com:44304/sap/bc/gui/sap/its/webgui/?~transaction=IW33%20AUFNR-LOW=91234911;" target="_blank"
            >
              <PlaylistAddCheckIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Ping Meter">
            <Fab color="primary" size="small" aria-label="Ping">
              <RssFeedIcon />
            </Fab>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function MDReconOutOfSyncDetail(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("meter_number");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setTableData] = React.useState([]);
  const [openTicketDetail, setOpenTicketDetail] = React.useState(false);
  const [selectedMeterNumber, setSelectedMeterNumber] = React.useState(null);

  React.useEffect(() => {
    const url = props.apiCode;
    if (url) {
      getDataFromApi(url, {division: 10}).then((data) => {
        setTableData(JSON.parse(data.body));
      });
    }
    else {
      setTableData(rows);
    }
  }, [props.apiCode, props.missingSystem]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.meter_number);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, meter_number) => {
    const selectedIndex = selected.indexOf(meter_number);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, meter_number);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log(newSelected);
    setSelected(newSelected);
  };

  const handleMeterNumberClick = (event, meter_number) => {
    console.log(meter_number);
  };

  const handleTicketCountClick = (event, meter_number) => {
    setSelectedMeterNumber(meter_number);
    setOpenTicketDetail(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpenTicketDetail(false);
  };

  const isSelected = (meter_number) => selected.indexOf(meter_number) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            disableSelectionOnClick
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.meter_number);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Fragment>
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.meter_number}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox" rowSpan={row.attributes.length + 1}>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.meter_number)}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          rowSpan={row.attributes.length + 1}
                        >
                          <Link href={ SAPConfig.sap_endpoint["IE03"] + row.meter_number } target="_blank">{row.meter_number}</Link>
                        </TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>{row.billing_class}</TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>{row.cycle}</TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>{row.service_order_count}</TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>
                          {
                            row.tickets_count &&
                            <Link href="#" onClick={(event) => handleTicketCountClick(event, row.meter_number)}>{row.tickets_count}</Link>
                          }
                        </TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>{row.auto_sync_status}</TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>{row.ping_status}</TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>{row.manual_sync_status}</TableCell>
                        <TableCell rowSpan={row.attributes.length + 1}>{row.manual_sync_request_time}</TableCell>
                      </TableRow>
                      {
                        row.attributes.map(att => (
                          <TableRow>
                            <TableCell >{att.attribute}</TableCell>
                            <TableCell >{att.sap_value}</TableCell>
                            <TableCell >{att.mdm_value}</TableCell>
                            <TableCell >{att.hes_value}</TableCell>
                          </TableRow>
                        ))
                      }
                    </Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openTicketDetail}
      >
        <div style={{ display: "flex" }}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Ticket Details
          </DialogTitle>
          <span className="spacer"></span>
          <IconButton
            color="secondary"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers>
          <TicketDetail apiCode="apiForCaseList" selectedMeterNumber={selectedMeterNumber} />
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default MDReconOutOfSyncDetail;
