import React, { useEffect, useState } from "react";
import MA4UChartDetail from "../shared/MA4UChartDetail";
import ChartSkeleton from "../shared/ChartSkeleton";
import { getDataFromApi } from "../../services/rest-api.service";

function AttributeStatus(props) {
  const [loading, setLoading] = useState(true);
  const [attribStatusData, setAttribStatusData] = useState({
    options: {},
    series: [],
  });
  useEffect(() => {
    const division = props.division ? props.division : 10;
    getDataFromApi("apiForAttributeStatus", {division: division}).then((data) => {
      const parsed_data = JSON.parse(data.body);
      setAttribStatusData({
        options: {
          colors: ['#4CAF50', '#FF9800'],
          chart: {
            id: "attribStatusChart",
            stacked: true,
            height: 200,
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                if (props)
                  props.setDetailReportName(config.w.globals.labels[config.dataPointIndex]);
                },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          noData: {
            text: "Loading...",
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              if (val > 0)
                return val;
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "center",
          },
          theme: {
            mode: "light",
            palette: "palette2",
          },
          xaxis: {
            categories: parsed_data.categories,
          },
        },
        series: parsed_data.series,
      });
      setLoading(false);
    });
    
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [props.division]);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChartDetail
          title="Mismatched Attributes"
          type="bar"
          options={attribStatusData.options}
          series={attribStatusData.series}
          height="300"
        />
      )}
    </div>
  );
}

export default AttributeStatus;
