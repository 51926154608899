import React from "react";
import Grid from '@material-ui/core/Grid';

import MA4UKPISimple from "../shared/MA4UKPISimple";
import MA4UKPITriple from "../shared/MA4UKPITriple";

function MDReconKPI(props) {
 
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MA4UKPITriple value1={props.value1} value2={props.value2} value3={props.value3} key1="MD01" key2="MD02" key3="EX01" />
        </Grid>
        <Grid item xs={12}>
          <MA4UKPISimple value={props.total} label={"Total count of mismatched meters"} color={"error.main"} />
         </Grid>
      </Grid>
    </div>
  );
}

export default MDReconKPI;
