import React from "react";
import DayJS from "react-dayjs";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { getDataFromApi } from "../../services/rest-api.service";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: 400,
    // overflow: auto,
  },
});

const rows = [
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "805231",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2017-11-02",
    MTR_ID: "106231",
    OPT_OUT_START_DATE: "Mon, 07 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "807725",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2016-11-16",
    MTR_ID: "108725",
    OPT_OUT_START_DATE: "Mon, 07 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "806770",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2017-11-08",
    MTR_ID: "107770",
    OPT_OUT_START_DATE: "Thu, 10 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "805229",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2019-12-22",
    MTR_ID: "106229",
    OPT_OUT_START_DATE: "Thu, 10 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "802317",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2018-03-20",
    MTR_ID: "103317",
    OPT_OUT_START_DATE: "Fri, 18 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "700887",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2017-03-11",
    MTR_ID: "100887",
    OPT_OUT_START_DATE: "Thu, 24 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "808799",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2018-04-02",
    MTR_ID: "109799",
    OPT_OUT_START_DATE: "Sat, 26 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "803931",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2017-10-07",
    MTR_ID: "104931",
    OPT_OUT_START_DATE: "Sat, 26 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "700919",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2019-12-19",
    MTR_ID: "100919",
    OPT_OUT_START_DATE: "Wed, 30 Oct 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "806775",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2018-11-29",
    MTR_ID: "107775",
    OPT_OUT_START_DATE: "Fri, 01 Nov 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "800383",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2017-11-19",
    MTR_ID: "101383",
    OPT_OUT_START_DATE: "Sun, 10 Nov 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "803027",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2016-03-16",
    MTR_ID: "104027",
    OPT_OUT_START_DATE: "Wed, 13 Nov 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "802427",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2018-05-27",
    MTR_ID: "103427",
    OPT_OUT_START_DATE: "Fri, 15 Nov 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "805177",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2018-01-09",
    MTR_ID: "106177",
    OPT_OUT_START_DATE: "Thu, 21 Nov 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "803049",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2018-09-08",
    MTR_ID: "104049",
    OPT_OUT_START_DATE: "Sun, 24 Nov 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
  {
    HES_METER_STATUS: "RETIRED",
    INSTALLATION: "804428",
    MDM_METER_STATUS: "RETIRED",
    METER_CATEGORY: "RES",
    METER_INSTALLATION_DATE: "2016-08-01",
    MTR_ID: "105428",
    OPT_OUT_START_DATE: "Mon, 02 Dec 2019 00:00:00 GMT",
    RADIO_STATUS: "CONNECTED",
    SAP_METER_STATUS: "RETIRED",
  },
];

function OptOutCustomersDetail() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    getDataFromApi("apiForOptOutDetail").then((data) => {
      console.log(data);
      setTableData(data);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Opt Out Customers Detail
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="a dense table"
            style={{ width: "auto", tableLayout: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Installation</TableCell>
                <TableCell>Meter ID</TableCell>
                <TableCell>Meter Category</TableCell>
                <TableCell width="20%">Meter Inst. Date</TableCell>
                <TableCell>Meter Type</TableCell>
                <TableCell>Radio Status</TableCell>
                <TableCell align="center">HES Meter Status</TableCell>
                <TableCell>MDM Meter Status</TableCell>
                <TableCell>SAP Meter Status</TableCell>
                <TableCell>Opt-Out Start Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.MTR_ID}>
                  <TableCell>{row.INSTALLATION}</TableCell>
                  <TableCell>{row.MTR_ID}</TableCell>
                  <TableCell>{row.METER_CATEGORY}</TableCell>
                  <TableCell width="20%">
                    <DayJS format="YYYY-MM-DD">
                      {row.METER_INSTALLATION_DATE}
                    </DayJS>
                  </TableCell>
                  <TableCell>{row.MDM_METER_STATUS}</TableCell>
                  <TableCell>{row.RADIO_STATUS}</TableCell>
                  <TableCell>{row.HES_METER_STATUS}</TableCell>
                  <TableCell>{row.MDM_METER_STATUS}</TableCell>
                  <TableCell>{row.SAP_METER_STATUS}</TableCell>
                  <TableCell>
                    <DayJS format="YYYY-MM-DD">{row.OPT_OUT_START_DATE}</DayJS>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

export default OptOutCustomersDetail;
