import React, { useEffect, useState } from "react";
import MA4UChart from "../shared/MA4UChart";
import ChartSkeleton from "../shared/ChartSkeleton";
import { getDataFromApi } from "../../services/rest-api.service";

function MissReadTrend() {
  const [loading, setLoading] = useState(true);
  const [missReadTrendData, setMissReadTrendData] = useState({
    options: {},
    series: [],
  });
  const [seriesData, setSeriesData] = useState([
    // {
    //   name: "Missed Read",
    //   data: [137, 0, 0, 26],
    // },
  ]);

  useEffect(() => {
    // getDataFromApi("apiForMissReadTrend").then((data) => {
    //   console.log(data, Object.values(data[0]));
    //   // const seriesData = Object.values(data[0]);
    //   setSeriesData([
    //     {
    //       name: "Missed Reads",
    //       data: Object.values(data[0]),
    //     },
    //   ]);
    //   setLoading(false);
    // });
    setMissReadTrendData({
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "14/03/2020",
            "15/03/2020",
            "16/03/2020",
            "17/03/2020",
            "18/03/2020",
            "19/03/2020",
            "20/03/2020",
            "21/03/2020",
          ],
        },
      },
      series: [
        {
          name: "Missed Reads",
          data: [162, 169, 172, 184, 149, 231, 146, 163],
        },
      ],
    });
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <MA4UChart
          title="Register Missed Read Meter Count"
          type="area"
          options={missReadTrendData.options}
          series={missReadTrendData.series}
          height="250"
        />
      )}
    </div>
  );
}

export default MissReadTrend;
