import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
  },
  number: {
    textAlign: 'center',
  },
}));

function MA4UKPISimple(props) {
  const classes = useStyles();

  return (
    <div>
      <Card>
        <CardContent>
          <Typography className={classes.number} variant="h5" component="h2">
            <Link href="#">
              <Box color={props.color}>{props.value}</Box>
            </Link>
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {props.label}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default MA4UKPISimple;
